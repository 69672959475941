// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #92949c;
  --ion-color-primary-rgb: 146, 148, 156;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #808289;
  --ion-color-primary-tint: #9d9fa6;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // theme
  --ion-color-theme: #323c4e;
	--ion-color-theme-rgb: 50,60,78;
	--ion-color-theme-contrast: #ffffff;
	--ion-color-theme-contrast-rgb: 255,255,255;
	--ion-color-theme-shade: #2c3545;
	--ion-color-theme-tint: #475060;

  // ES
  --ion-color-es: #d8000c;
  --ion-color-es-rgb: 216, 0, 12;
  --ion-color-es-contrast: #ffffff;
  --ion-color-es-contrast-rgb: 255, 255, 255;
  --ion-color-es-shade: #be000b;
  --ion-color-es-tint: #dc1a24;

  // MS
  --ion-color-ms: #347d45;
  --ion-color-ms-rgb: 52, 125, 69;
  --ion-color-ms-contrast: #ffffff;
  --ion-color-ms-contrast-rgb: 255, 255, 255;
  --ion-color-ms-shade: #2e6e3d;
  --ion-color-ms-tint: #488a58;

  // HS
  --ion-color-hs: #3b35de;
  --ion-color-hs-rgb: 59, 53, 222;
  --ion-color-hs-contrast: #ffffff;
  --ion-color-hs-contrast-rgb: 255, 255, 255;
  --ion-color-hs-shade: #342fc3;
  --ion-color-hs-tint: #4f49e1;

  // UP
  --ion-color-up: #663775;
	--ion-color-up-rgb: 102,55,117;
	--ion-color-up-contrast: #ffffff;
	--ion-color-up-contrast-rgb: 255,255,255;
	--ion-color-up-shade: #5a3067;
	--ion-color-up-tint: #754b83;
}

.ion-color-theme {
  --ion-color-base: var(--ion-color-theme);
  --ion-color-base-rgb: var(--ion-color-theme-rgb);
  --ion-color-contrast: var(--ion-color-theme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
  --ion-color-shade: var(--ion-color-theme-shade);
  --ion-color-tint: var(--ion-color-theme-tint);
}

.ion-color-es {
  --ion-color-base: var(--ion-color-es);
  --ion-color-base-rgb: var(--ion-color-es-rgb);
  --ion-color-contrast: var(--ion-color-es-contrast);
  --ion-color-contrast-rgb: var(--ion-color-es-contrast-rgb);
  --ion-color-shade: var(--ion-color-es-shade);
  --ion-color-tint: var(--ion-color-es-tint);
}

.ion-color-ms {
  --ion-color-base: var(--ion-color-ms);
  --ion-color-base-rgb: var(--ion-color-ms-rgb);
  --ion-color-contrast: var(--ion-color-ms-contrast);
  --ion-color-contrast-rgb: var(--ion-color-ms-contrast-rgb);
  --ion-color-shade: var(--ion-color-ms-shade);
  --ion-color-tint: var(--ion-color-ms-tint);
}

.ion-color-hs {
	--ion-color-base: var(--ion-color-hs);
	--ion-color-base-rgb: var(--ion-color-hs-rgb);
	--ion-color-contrast: var(--ion-color-hs-contrast);
	--ion-color-contrast-rgb: var(--ion-color-hs-contrast-rgb);
	--ion-color-shade: var(--ion-color-hs-shade);
	--ion-color-tint: var(--ion-color-hs-tint);
}

.ion-color-up {
	--ion-color-base: var(--ion-color-up);
	--ion-color-base-rgb: var(--ion-color-up-rgb);
	--ion-color-contrast: var(--ion-color-up-contrast);
	--ion-color-contrast-rgb: var(--ion-color-up-contrast-rgb);
	--ion-color-shade: var(--ion-color-up-shade);
	--ion-color-tint: var(--ion-color-up-tint);
}

.custom-loading {
  --background: var(--ion-color-theme-contrast);
  --spinner-color: var(--ion-color-theme);
  color: var(--ion-color-theme);
}

// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --ion-color-primary: #989aa2;
//     --ion-color-primary-rgb: 152, 154, 162;
//     --ion-color-primary-contrast: #000000;
//     --ion-color-primary-contrast-rgb: 0, 0, 0;
//     --ion-color-primary-shade: #86888f;
//     --ion-color-primary-tint: #a2a4ab;

//     --ion-color-secondary: #50c8ff;
//     --ion-color-secondary-rgb: 80,200,255;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255,255,255;
//     --ion-color-secondary-shade: #46b0e0;
//     --ion-color-secondary-tint: #62ceff;

//     --ion-color-tertiary: #6a64ff;
//     --ion-color-tertiary-rgb: 106,100,255;
//     --ion-color-tertiary-contrast: #ffffff;
//     --ion-color-tertiary-contrast-rgb: 255,255,255;
//     --ion-color-tertiary-shade: #5d58e0;
//     --ion-color-tertiary-tint: #7974ff;

//     --ion-color-success: #2fdf75;
//     --ion-color-success-rgb: 47,223,117;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #29c467;
//     --ion-color-success-tint: #44e283;

//     --ion-color-warning: #ffd534;
//     --ion-color-warning-rgb: 255,213,52;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #e0bb2e;
//     --ion-color-warning-tint: #ffd948;

//     --ion-color-danger: #ff4961;
//     --ion-color-danger-rgb: 255,73,97;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255,255,255;
//     --ion-color-danger-shade: #e04055;
//     --ion-color-danger-tint: #ff5b71;

//     --ion-color-dark: #f4f5f8;
//     --ion-color-dark-rgb: 244,245,248;
//     --ion-color-dark-contrast: #000000;
//     --ion-color-dark-contrast-rgb: 0,0,0;
//     --ion-color-dark-shade: #d7d8da;
//     --ion-color-dark-tint: #f5f6f9;

//     --ion-color-medium: #989aa2;
//     --ion-color-medium-rgb: 152,154,162;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #86888f;
//     --ion-color-medium-tint: #a2a4ab;

//     --ion-color-light: #222428;
//     --ion-color-light-rgb: 34,36,40;
//     --ion-color-light-contrast: #ffffff;
//     --ion-color-light-contrast-rgb: 255,255,255;
//     --ion-color-light-shade: #1e2023;
//     --ion-color-light-tint: #383a3e;
//   }

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

// .ios body {
//   --ion-background-color: #000000;
//   --ion-background-color-rgb: 0,0,0;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-color-step-50: #0d0d0d;
//   --ion-color-step-100: #1a1a1a;
//   --ion-color-step-150: #262626;
//   --ion-color-step-200: #333333;
//   --ion-color-step-250: #404040;
//   --ion-color-step-300: #4d4d4d;
//   --ion-color-step-350: #595959;
//   --ion-color-step-400: #666666;
//   --ion-color-step-450: #737373;
//   --ion-color-step-500: #808080;
//   --ion-color-step-550: #8c8c8c;
//   --ion-color-step-600: #999999;
//   --ion-color-step-650: #a6a6a6;
//   --ion-color-step-700: #b3b3b3;
//   --ion-color-step-750: #bfbfbf;
//   --ion-color-step-800: #cccccc;
//   --ion-color-step-850: #d9d9d9;
//   --ion-color-step-900: #e6e6e6;
//   --ion-color-step-950: #f2f2f2;

//   --ion-item-background: #000000;

//   --ion-card-background: #1c1c1d;
// }

// .ios ion-modal {
//   --ion-background-color: var(--ion-color-step-100);
//   --ion-toolbar-background: var(--ion-color-step-150);
//   --ion-toolbar-border-color: var(--ion-color-step-250);
// }


/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

// .md body {
//   --ion-background-color: #121212;
//   --ion-background-color-rgb: 18,18,18;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-border-color: #222222;

//   --ion-color-step-50: #1e1e1e;
//   --ion-color-step-100: #2a2a2a;
//   --ion-color-step-150: #363636;
//   --ion-color-step-200: #414141;
//   --ion-color-step-250: #4d4d4d;
//   --ion-color-step-300: #595959;
//   --ion-color-step-350: #656565;
//   --ion-color-step-400: #717171;
//   --ion-color-step-450: #7d7d7d;
//   --ion-color-step-500: #898989;
//   --ion-color-step-550: #949494;
//   --ion-color-step-600: #a0a0a0;
//   --ion-color-step-650: #acacac;
//   --ion-color-step-700: #b8b8b8;
//   --ion-color-step-750: #c4c4c4;
//   --ion-color-step-800: #d0d0d0;
//   --ion-color-step-850: #dbdbdb;
//   --ion-color-step-900: #e7e7e7;
//   --ion-color-step-950: #f3f3f3;

//   --ion-item-background: #1e1e1e;

//   --ion-toolbar-background: #1f1f1f;

//   --ion-tab-bar-background: #1f1f1f;

//   --ion-card-background: #1e1e1e;
// }
// }