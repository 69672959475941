/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'Conthrax';
    src: url('assets/fonts/conthrax-sb.otf');
}

.conthrax {
    font-family: 'Conthrax', sans-serif;
}

ion-header ion-toolbar:first-of-type {
    padding-top: 10px;
}

.text-bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.alert-confirm {
    text-align: center;
}

ion-select {
    vertical-align: middle !important;
}

.ngx-pagination .current {
    background-color: var(--ion-color-theme) !important;
    color: var(--ion-color-theme-contrast) !important;
    border-radius: 4px !important;
}


ion-item {

    --highlight-height: 0px;
}

// ion-input {
//     width: 100%;
//     padding: 12px 20px !important;
//     margin: 8px 0 !important;
//     display: inline-block;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     box-sizing: border-box;
// }

.ion-select-boxed {
    width: 100%;
    border: 1px solid #ccc;
    margin: 8px 0 !important;
    padding: 20px 20px !important;
    border-radius: 4px;
}

// scroll
/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// imput
.input-wrapper {
    position: relative;
    width: 100%;
}

.input {
    box-sizing: border-box;
    border-color: #c9c9c9;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    color: #000000;
    padding: 15px 15px 15px 35px;
    width: 100%;
}

.input::placeholder {
    color: rgb(163, 163, 163);
}


.input.password {
    padding: 15px 35px 15px 15px;
}

.input-icon {
    color: var(--ion-color-theme);
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.input-icon.password {
    left: unset;
    right: 12px;
}

.item-input {
    margin-bottom: 15px;
}

.fab-description {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    right: 65px;
    background-color: #0000008d;
    color: #ffffff;
    padding: 5px;
    border-radius: 5px;
    text-align: right;
    white-space: nowrap;
}

.fab-option {
    height: 60px;
    width: 60px;
    margin-top: 10px;
}

.relative {
    position: relative;
    ;
}

// table
table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #000000;
    text-align: center;
}

tr:nth-child(even) {
    background-color: #f0f0f0;
}

// popover
ion-popover {
    --min-width: min-content;
}

ion-modal {
    --border-radius: 10px;
}

// modals
.ion-modal-action {
    --min-width: 370px !important;
    --width: 370px !important;
    --height: min-content !important;
}

@media only screen and (max-width: 600px) {
    ion-modal {
        --height: 99vh;
        --width: 99vw;
    }

    .ion-modal-action {
        --min-width: 99vw !important;
        --width: 99vw !important;
    }
}

.barcode {
    svg {
        width: 100% !important;
    }
}

ion-card-title {
    font-size: large;
}

textarea:focus,
input:focus,
input[type]:focus {
    outline: 0 none;
}

img {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

.match-avatar {
    height: 50px;
    vertical-align: middle;
    border-radius: 10px;
}


.qr-code {

    img{
        height: 100%;
        width: 100%;
    }
}

// tournament
.text-tournament {
    font-size: 9px;
    width: 87px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    position: absolute;
  }
  
  .img-tournament {
    height: 85px;
    width: 85px;
    position: absolute;
    border-radius: 50%;
  }
  
  .player-winner {
    border-color: #adff2f;
    border-width: 3px;
    border-style: solid;
  }
  
  .quarter-quarter-a0-img {
    top: 156px;
    left: 70px;
  }
  
  .quarter-quarter-a0-text {
    top: 246px;
    left: 69px;
    width: 87px;
  }
  
  .quarter-quarter-a1-img {
    top: 156px;
    left: 214px;
  }
  
  .quarter-quarter-a1-text {
    top: 246px;
    left: 213px;
    width: 87px;
  }
  
  .quarter-quarter-b0-img {
    top: 156px;
    right: 73px;
  }
  
  .quarter-quarter-b0-text {
    top: 246px;
    right: 69px;
    width: 87px;
  }
  
  .quarter-quarter-b1-img {
    top: 156px;
    right: 218px;
  }
  
  .quarter-quarter-b1-text {
    top: 246px;
    right: 213px;
    width: 87px;
  }
  
  .quarter-quarter-c0-img {
    top: 616px;
    right: 73px;
  }
  
  .quarter-quarter-c0-text {
    top: 706px;
    right: 69px;
    width: 87px;
  }
  
  .quarter-quarter-c1-img {
    top: 616px;
    right: 218px;
  }
  
  .quarter-quarter-c1-text {
    top: 706px;
    right: 215px;
    width: 87px;
  }
  
  
  .quarter-quarter-d0-img {
    top: 616px;
    left: 70px;
  }
  
  .quarter-quarter-d0-text {
    top: 706px;
    left: 69px;
    width: 87px;
  }
  
  .quarter-quarter-d1-img {
    top: 616px;
    left: 214px;
  }
  
  .quarter-quarter-d1-text {
    top: 706px;
    left: 213px;
    width: 87px;
  }


  .quarter-semifinal-a0-img {
    top: 384px;
    left: 233px;
  }
  
  .quarter-semifinal-a0-text {
    top: 474px;
    left: 231px;
    width: 87px;
  }
  
  .quarter-semifinal-a1-img {
    top: 384px;
    left: 378px;
  }
  
  .quarter-semifinal-a1-text {
    top: 474px;
    left: 380px;
    width: 87px;
  }

  

  .quarter-semifinal-b0-img {
    top: 384px;
    right: 243px;
  }
  
  .quarter-semifinal-b0-text {
    top: 474px;
    right: 242px;
    width: 87px;
  }
  
  .quarter-semifinal-b1-img {
    top: 384px;
    right: 387px;
  }
  
  .quarter-semifinal-b1-text {
    top: 474px;
    right: 386px;
    width: 87px;
  }


  .quarter-3-4-0-img {
    top: 506px;
    right: 641px;
  }
  
  .quarter-3-4-0-text {
    top: 596px;
    right: 640px;
    width: 87px;
  }
  
  .quarter-3-4-1-img {
    top: 506px;
    right: 496px;
  }
  
  .quarter-3-4-1-text {
    top: 596px;
    right: 495px;
    width: 87px;
  }


  .quarter-finals-0-img {
    top: 211px;
    right: 641px;
  }
  
  .quarter-finals-0-text {
    top: 301px;
    right: 640px;
    width: 87px;
  }
  
  .quarter-finals-1-img {
    top: 211px;
    right: 496px;
  }
  
  .quarter-finals-1-text {
    top: 301px;
    right: 495px;
    width: 87px;
  }


  // Semifinals
  .semifinal-semifinal-a0-img {
    top: 260px;
    left: 55px;
  }
  
  .semifinal-semifinal-a0-text {
    top: 354px;
    left: 54px;
    width: 87px;
  }
  
  .semifinal-semifinal-a1-img {
    top: 260px;
    left: 200px;
  }
  
  .semifinal-semifinal-a1-text {
    top: 354px;
    left: 199px;
    width: 87px;
  }

  

  .semifinal-semifinal-b0-img {
    top: 260px;
    right: 67px;
  }
  
  .semifinal-semifinal-b0-text {
    top: 354px;
    right: 66px;
    width: 87px;
  }
  
  .semifinal-semifinal-b1-img {
    top: 260px;
    right: 211px;
  }
  
  .semifinal-semifinal-b1-text {
    top: 354px;
    right: 210px;
    width: 87px;
  }


  
  .semifinal-3-4-0-img {
    top: 382px;
    right: 465px;
  }
  
  .semifinal-3-4-0-text {
    top: 476px;
    right: 464px;
    width: 87px;
  }
  
  .semifinal-3-4-1-img {
    top: 382px;
    right: 320px;
  }
  
  .semifinal-3-4-1-text {
    top: 476px;
    right: 319px;
    width: 87px;
  }


  
  .semifinals-finals-0-img {
    top: 85px;
    right: 465px;
  }
  
  .semifinals-finals-0-text {
    top: 179px;
    right: 464px;
    width: 87px;
  }
  
  .semifinals-finals-1-img {
    top: 85px;
    right: 320px;
  }
  
  .semifinals-finals-1-text {
    top: 179px;
    right: 319px;
    width: 87px;
  }